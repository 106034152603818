<template>
    <div class="flex flex-auto">
        <div class="content-area">

            <Accordion :multiple="true" :activeIndex="[1]">
                <AccordionTab header="Neuen Zugang einrichten">
                    
                    <div class="flex flex-inline mt-3">
                        <div >
                            <span class="p-float-label w-full">
                                <InputText id="newaccessusername" maxlength="32" style="min-width: 250px" v-model="m_NewUserName" @keyup.enter="createDeviceAccess" />
                                <label for="newaccessusername">Name der berechtigten Person</label>
                            </span>
                        </div>

                        <Button v-if="m_NewUserName.length>0" class="mx-3" label="Einrichten" icon="pi pi-user-edit" @click="createDeviceAccess"/>
                    </div>

                </AccordionTab>
                <AccordionTab header="Zugänge verwalten">
                    <DataTable :value="devices" :paginator="true" :rows="10"
                            dataKey="r" 
                            :rowHover="true"
                            v-model:filters="m_Filters" 
                            filterDisplay="menu" 
                            :loading="loading"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                            currentPageReportTemplate="Seite {first} von {last}. Ergebnisse pro Seite"
                            :rowsPerPageOptions="[10,200]"
                            :globalFilterFields="['personname','devicename']" 
                            responsiveLayout="scroll">
                            <template #header>
                                <div class="flex flex-auto justify-content-between align-content-between m-0">
                                    <h5 class="">App Zugänge</h5>
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="m_Filters['global'].value" placeholder="Filtern" />
                                    </span>
                                </div>
                            </template>
                            <template #empty>
                                Keine Ergebnisse
                            </template>
                            <template #loading>
                                Die freigeschalteten Endgeräte werden geladen...
                            </template>

                        <Column field="personname" header="Name"></Column>
                        <Column field="devicename" header="Endgerät"></Column>
                        <Column field="expires" header="Gültig bis"></Column>
                        <Column header="Zugang löschen"
                            headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteDeviceAccess(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                </AccordionTab>
            </Accordion>

            <Dialog v-model:visible="m_bDeleteDeviceAccessDialog" :style="{width: '650px'}"  header="Bitte bestätigen Sie die Aktion" :modal="true">
                <div class="confirmation-content">
                    <i class="pi pi-exclamation-triangle mx-3" style="font-size: 2rem" />
                    <span v-if="m_SelectedDevice">Wollen Sie wirklich den Zugang von <b>{{m_SelectedDevice.personname}}@{{m_SelectedDevice.devicename}}</b> entfernen?</span>
                </div>
                <template #footer>
                    <Button label="Noch nicht" icon="pi pi-times" class="p-button-text" @click="m_bDeleteDeviceAccessDialog = false"/>
                    <Button label="Ja, entfernen" icon="pi pi-check" class="p-button-text" @click="deleteDeviceAccess" />
                </template>
            </Dialog>

            <Dialog header="QR-Code scannen" v-model:visible="m_bShowModal"
                    :breakpoints="{'1260px': '65vw', '640px': '100vw'}" 
                    :style="{width: '30vw'}"
                    :modal="true"
                    @hide="onCloseQRCode" >
                <div class="m-0 p-4">
                    <div class="flex flex-auto flex-column text-center align-content-center  justify-content-center">
                        <h3>{{displayTextForNewAccess}}</h3>
                        <div class="flex flex-none justify-content-center" >
                            <div style="background-color: white" class="m-3 shadow-4" >
                                <QrcodeVue :size="200" class="m-4" v-bind:value="qrcodeurl" />
                            </div>
                        </div>
                        
                        <p class="text-sm" style="word-wrap:break-word;" >{{qrcodeurl}}</p>

                        <p>
                            Der Zugang ist eingerichtet und wird automatisch gelösch, sobald die Zeit abläuft, wenn es nicht mit der E-Plant App gescannt wird.
                        </p>
                        <Knob v-model="qrcodetimeleft" :size="120" :min="0" :max="maxqrcodedisplaytime" readonly valueTemplate="{value}s" />
                    </div>
                </div>
                <template #footer>
                    
                </template>
            </Dialog>


        </div>
    </div>

</template>


<script>
import { ref, computed, onMounted, watch} from  "vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
//import Password from 'primevue/password';
import Dialog from 'primevue/dialog';
import Knob from 'primevue/knob';

import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useRestApi } from "../RESTService";
import QrcodeVue from 'qrcode.vue'
import { processErrorResponseWithToast } from "../epcommon";

export default ({
    name: "Users",
    components: { DataTable, Column, Dialog, QrcodeVue, Knob },
    setup() {

        const router = useRouter();
        const store = useStore()
        const restService = useRestApi();

        const toast = useToast();
        const loading = ref(true);
        const m_Filters = ref({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
        
        const m_NewUserName = ref(""); // user name entered for the new user
        const m_AccessCode = ref("");
        const m_SelectedDevice = ref(null);
        const m_bDeleteDeviceAccessDialog = ref(false);

        const maxqrcodedisplaytime = ref(180);
        const qrcodetimeleft = ref(0);
        const qrcodetimer = ref(null);
        const checkClaimTimer = ref(null);

        const m_bShowModal = ref(false);
        

        let m_componentMounted = false;
        let m_componentInitialized = false;

        onMounted(() => {
            m_componentMounted = true;
            initWhenReady();

            if (!store.getters.isloggedin) { router.push("/login"); }
        });

        watch(() => store.getters.isready, function () { initWhenReady(); });
        watch(() => store.getters.devices, function () { loading.value = false; });

        const initWhenReady = async () => {
            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;
            
            store.dispatch("refreshDevices");
        }

        const devices = computed( () => { 
            return store.getters.devices;
        });

        const qrcodeurl = computed(() => {
            if (m_AccessCode.value.length > 0) {
                return process.env.VUE_APP_API_ENDPOINT_BASEURL_M + "claimaccess/" + m_AccessCode.value;
            }
            return "";
        });

        const stopTimers = () => {
            if (qrcodetimer.value)
            {
                clearInterval(qrcodetimer.value);
                qrcodetimer.value = null;
            }

            if (checkClaimTimer.value) {
                clearInterval(checkClaimTimer.value);
                checkClaimTimer.value = null;
            }
        }

        const onCloseQRCode = () => {
            m_AccessCode.value = "";
            m_bShowModal.value = false;
            stopTimers();
            qrcodetimeleft.value = 0;
            store.dispatch("refreshDevices");
        };

        const onCountdownTick = () => {
            qrcodetimeleft.value -= 1;
            if (qrcodetimeleft.value <= 0 || m_AccessCode.value.length == 0) {
                onCloseQRCode();
            }
        };

        const onCheckClaimTick = async () => {
            if (m_AccessCode.value.length > 0) {
                restService.getClaimStatus(m_AccessCode.value).then((response) => {
                    if (true === response.claimed) {
                        onCloseQRCode();
                    }
                }).catch(() => {});
            }
        }

        const onOpenQRCode = (newAccessCode) => {
            console.log("onOpenQRCode");
            m_AccessCode.value = newAccessCode;
            qrcodetimeleft.value = maxqrcodedisplaytime.value;
            stopTimers();
            qrcodetimer.value = setInterval(onCountdownTick, 1000);
            checkClaimTimer.value = setInterval(onCheckClaimTick, 8000);
            m_bShowModal.value = true;
        };

        const isQRcodeAvailable = computed(() => m_AccessCode.value.length > 0);

        const displayTextForNewAccess = computed(() => {
            if (m_NewUserName.value) {
                return "Scannen Sie jetzt den QR-Code mit dem Endgerät von " + m_NewUserName.value;
            }
            return "";
        });

        const confirmDeleteDeviceAccess = (prod) => {
            m_SelectedDevice.value = prod;
            m_bDeleteDeviceAccessDialog.value = true;
        };

        const deleteDeviceAccess = () => {
            if (m_SelectedDevice.value) {
                m_bDeleteDeviceAccessDialog.value = false;
                let pkey = m_SelectedDevice.value.pkey;
                m_SelectedDevice.value = null;

                restService.deleteDeviceAccess(pkey).then(() => {
                    toast.add({severity:'success', summary: 'Zugang entfernt', detail: 'Der Zugang wurde erfolgreich entfernt', life: 3000});
                    store.dispatch("refreshDevices");
                }).catch((error) => processErrorResponseWithToast(error, store));
            }
        };

        const createDeviceAccess = async () => {
            onCloseQRCode(); // Clear previous access code
            if (m_NewUserName.value.length > 0) {
                restService.grantDeviceAccess(store.state.currentInstallation, m_NewUserName.value, 365).then((response) => {
                    onOpenQRCode(response.accesscode);
                }).catch((error) => processErrorResponseWithToast(error, store));
            }
        }

       return {devices, m_Filters, loading, toast, m_SelectedDevice, m_bShowModal, displayTextForNewAccess, onCloseQRCode,
        m_bDeleteDeviceAccessDialog, confirmDeleteDeviceAccess, deleteDeviceAccess,
         m_NewUserName, createDeviceAccess, isQRcodeAvailable, qrcodeurl, maxqrcodedisplaytime, qrcodetimeleft };
    },
})
</script>

<style lang="scss" scoped>
img {
    vertical-align: middle;
}

::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }

}


.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
	::v-deep(.p-toolbar) {
		flex-wrap: wrap;
        
		.p-button {
            margin-bottom: 0.25rem;
        }
	}
}

</style>

